<div
  class="min-h-screen flex relative lg:static bg-gray-900 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-900/20 via-gray-900 to-gray-900 overflow-hidden">
  <div class="w-full flex flex-col items-center justify-center">
    <div class="flex flex-1 gap-4 h-full w-full">
      <!-- Main Content Container -->
      <div class="flex-1 flex-col flex justify-center items-center">
        <div
          class="w-full max-w-3xl bg-gray-800/40 backdrop-blur-xl border border-gray-700/50 rounded-2xl p-8 shadow-2xl transition-all duration-300 ease-in-out transform">
          <img
            src="/assets/images/logos/incidite-full-white.svg"
            alt="Incidite Logo"
            class="h-10 mb-6 mx-auto" />
          <div *ngIf="!isSubmitting">
            <h1 class="text-3xl font-bold text-white text-center">
              Welcome to Incidite
            </h1>
            <p class="text-gray-300 text-center text-lg mb-8">
              Tell us about your organization
            </p>
          </div>

          <div class="max-w-md mx-auto py-8" *ngIf="!isSubmitting">
            <form [formGroup]="newOrgForm" class="flex flex-col space-y-4">
              <v-input-field label="Organization Name" fControlName="orgName">
                <v-input
                  type="text"
                  fControlName="orgName"
                  placeholder="Enter organization name"
                  testId="org-name-input"></v-input>
              </v-input-field>

              <v-input-field label="Industry" fControlName="industry">
                <v-dropdown
                  fControlName="industry"
                  [options]="[
                    { label: 'Technology', value: 'technology' },
                    { label: 'Finance', value: 'finance' },
                    { label: 'Healthcare', value: 'healthcare' },
                    { label: 'Retail', value: 'retail' },
                    { label: 'Manufacturing', value: 'manufacturing' },
                    { label: 'Other', value: 'other' }
                  ]"
                  placeholder="Select industry"
                  testId="industry-dropdown"></v-dropdown>
              </v-input-field>

              <v-input-field label="Organization Size" fControlName="size">
                <v-dropdown
                  fControlName="size"
                  [options]="[
                    { label: '1-10 employees', value: '1-10' },
                    { label: '11-50 employees', value: '11-50' },
                    { label: '51-200 employees', value: '51-200' },
                    { label: '201-500 employees', value: '201-500' },
                    { label: '501+ employees', value: '501+' }
                  ]"
                  placeholder="Select size"
                  testId="size-dropdown"></v-dropdown>
              </v-input-field>
            </form>
          </div>

          <div
            class="flex flex-col items-center justify-center py-8 space-y-4"
            *ngIf="isSubmitting">
            <i
              class="pi pi-spin pi-spinner !text-4xl text-primary-inverted mb-4"></i>
            <div class="flex flex-col items-center space-y-2">
              <p class="text-2xl text-gray-300 font-semibold mb-4">
                Setting up your organization
              </p>
              <p class="text-md text-gray-400 opacity-0 animate-fade-up animate-once animate-duration-[500ms] animate-delay-[1000ms] animate-ease-in-out">
                Convincing the logs to be useful...
              </p>
              <p class="text-md text-gray-400 opacity-0 animate-fade-up animate-once animate-duration-[500ms] animate-delay-[2250ms] animate-ease-in-out">
                Snoozing all the “urgent” non-urgent alerts...
              </p>
              <p class="text-md text-gray-400 opacity-0 animate-fade-up animate-once animate-duration-[500ms] animate-delay-[3500ms] animate-ease-in-out">
                Accidentally deploying to production... wait, what?
              </p>
            </div>
          </div>

          <p-message
            severity="danger"
            styleClass="w-full mt-4"
            [text]="createOrganizationError"
            *ngIf="createOrganizationError"></p-message>

          <div
            class="align-self-end mt-4 flex justify-between"
            *ngIf="!isSubmitting">
            <v-button
              (press)="logout()"
              variant="secondary"
              [label]="'actions.logout' | translate"></v-button>

            <v-button
              [label]="'pages.createOrganization.createOrgBtn' | translate"
              icon="pi pi-plus"
              (press)="onFormSubmit()"
              [disabled]="!newOrgForm.valid"></v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
