import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { SharedCommonModule } from 'src/app/core/common.module';
import { VButtonComponent } from 'src/app/core/components/ui-kit/buttons/button/button.component';
import { VKitCardModule } from 'src/app/core/components/ui-kit/card/card.module';
import { VInputComponent } from 'src/app/core/components/ui-kit/input/input.component';
import { ResetOnboardingAction } from 'src/app/store/onboarding/onboarding.actions';
import { ChangeSubscriptionAction, LoadSubscriptionAction } from 'src/app/store/subscription/subscription.actions';
import { SubscriptionState } from 'src/app/store/subscription/subscription.state';
import { LoadUserAction, LogoutAction, SelectOrganizationAction } from 'src/app/store/user/user.actions';
import { environment } from 'src/environments/environment';

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation CreateOrganization($name: String!) {
    createOrganization(newOrganization: { name: $name }) {
      id
    }
  }
`;

@Component({
  selector: 'app-page-new-organization',
  standalone: true,
  imports: [
    SharedCommonModule,
    VKitCardModule,
    VInputComponent,
    VButtonComponent,
  ],
  templateUrl: './new-organization.component.html',
})
export class NewOrganizationPageComponent {
  private apollo = inject(Apollo);
  private router = inject(Router);
  private store = inject(Store);

  newOrgForm = new FormGroup({
    orgName: new FormControl('', Validators.required),
    industry: new FormControl('', Validators.required),
    size: new FormControl('', Validators.required),
  });

  isSubmitting = false;

  createOrganizationError?: string;

  onFormSubmit() {
    if (!this.newOrgForm.valid) return;

    this.createOrganizationError = undefined;
    this.newOrgForm.disable();

    this.isSubmitting = true;

    this.apollo
      .mutate<{ createOrganization: { id: string } }>({
        mutation: CREATE_ORGANIZATION_MUTATION,
        variables: {
          name: this.newOrgForm.value.orgName,
        },
      })
      .subscribe({
        next: (res) => {

          const actions = [
            new SelectOrganizationAction(res.data!.createOrganization.id),
            new LoadUserAction(),
            new ResetOnboardingAction(),
          ];

          // Create a timer that resolves after 3 seconds
          const timer = new Promise(resolve => setTimeout(resolve, 3000));

          const checkSubscription = async (attempt = 1, delay = 500): Promise<void> => {
            if (attempt > 10) return;

            await this.store.dispatch(new LoadSubscriptionAction()).toPromise();
            const canChange = this.store.selectSnapshot(SubscriptionState.canChangeSubscription);

            if (!canChange) {
              await new Promise(resolve => setTimeout(resolve, delay));
              return checkSubscription(attempt + 1, delay * 2);
            }
          };

          // Wait for both the actions and the timer to complete
          Promise.all([
            firstValueFrom(this.store.dispatch(actions)),
            checkSubscription(),
            timer
          ]).then(() => {
            this.store.dispatch(new ChangeSubscriptionAction({
              planName: 'pro',
              priceId: environment.productPriceIds.pro.monthly,
              isTrial: true,
            }, true)).subscribe({
              complete: () => {
                this.router.navigate(['/onboarding']);
              }
            })
          });
        },
        error: err => {
          this.isSubmitting = false;
          this.createOrganizationError = err.toString();
          this.newOrgForm.enable();
        },
      });
  }

  logout() {
    this.store.dispatch(new LogoutAction());
  }
}
