import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import {
  PermissionGuard,
  RequiredRolesAnnotation,
} from 'src/app/core/guards/permission.guard';
import { HomePageComponent } from './home/home.component';
import { InternalLayoutComponent } from './internal.component';
import { SettingsShellPageComponent } from './settings/pages/settings.component';

export const InternalRoutes: Routes = [
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
  },
  {
    path: '',
    component: InternalLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        component: HomePageComponent,
        data: {
          title: 'menu.dashboard',
          icon: 'pi-home',
        },
      },
      {
        path: 'incidents',
        data: {
          title: 'menu.incidents',
          icon: 'pi-bolt',
          [RequiredRolesAnnotation]: ['incidents.reader'],
        },
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./incidents/incidents.module').then(m => m.IncidentsModule),
      },
      {
        path: 'alerts',
        data: {
          title: 'menu.alerts',
          icon: 'pi-exclamation-triangle',
          [RequiredRolesAnnotation]: ['alerts.reader'],
        },
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./alerts/alerts.module').then(m => m.AlertsModule),
      },
      {
        path: 'services',
        data: {
          title: 'menu.services',
          icon: 'pi-th-large',
          [RequiredRolesAnnotation]: ['services.reader'],
        },
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./services/services.module').then(m => m.ServicesModule),
      },
      {
        path: 'statuspages',
        data: {
          title: 'menu.statuspages',
          icon: 'pi-clone',
          [RequiredRolesAnnotation]: ['statuspages.reader'],
        },
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./statuspages/statuspages.module').then(
            m => m.StatuspageModule
          ),
      },
      {
        path: 'monitors',
        data: {
          title: 'menu.monitors',
          icon: 'pi-wave-pulse',
          [RequiredRolesAnnotation]: ['monitors.reader'],
        },
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./monitors/monitors.module').then(m => m.MonitorsModule),
      },
      {
        path: 'teams',
        data: {
          title: 'menu.teams',
          icon: 'pi-users',
          [RequiredRolesAnnotation]: ['teams.reader'],
        },
        canActivate: [PermissionGuard],
        loadChildren: () =>
          import('./teams/teams.module').then(m => m.TeamsModule),
      },
      {
        path: 'settings',
        component: SettingsShellPageComponent,
        loadChildren: () =>
          import('./settings/settings.module').then(m => m.SettingsModule),
        data: {
          title: 'menu.settings',
          icon: 'pi-cog',
        },
      },
    ],
  },
];
